<template>
	<div class="userinfo-container">
		<div class="userinfo-box">
			<div class="show-container">
				<!-- <p>公司项目</p> -->
				<ul>
					<li class="list-head"><span>项目名称</span><span>创建人</span><span>大小</span><span></span></li>
					<li v-for="item of projectList" :key="item.id">
						<span>{{item.projectName}}</span><span>{{item.usertName}}</span><span>无限制</span><span>
							<el-button v-if="item.projectOwnerId!=userId" type="text" @click="joinAndLeave(item)"><span v-if="item.inProject">离开</span><span v-else>加入</span ></el-button><span style="color:#f75130 ;"  v-if="item.projectOwnerId!=userId" type="text">|</span>
							<el-button type="text" @click="deleteProject(item)">删除</el-button>
						</span>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  data () {
    return {
      projectList: [],
      userId: 0
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      // this.userId
      this.userId = localStorage.getItem('USER_ID')
      this.$axios.get(`/api/project/getprojectlistbyspaceid?spaceId=${localStorage.getItem('eleSpaceId')}`).then(res => {
        console.log(res)
        this.projectList = res
      })
    },
    back () {
      this.$router.back()
    },
    deleteProject (item) {
      this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get(`/api/project/deleteproject?projectId=${item.id}`).then(res => {
          // console.log(res)
          if (res == '删除成功') {
            // 走本组件删除
            let index = this.projectList.findIndex(one => {
              return item.id == one.id
            })
            this.projectList.splice(index, 1)
            // 走localstroage中删除
            let localList = JSON.parse(localStorage.getItem('projectList'))
            item.projectTypeId = 'card' + item.projectTypeId
            let indexType = localList.findIndex(two => {
              return item.projectTypeId == two.id
            })
            localList[indexType].projectListDto.splice(index, 1)
            localStorage.setItem('projectList', JSON.stringify(localList))
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          } else {
            this.$message({
              type: 'error',
              message: res
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async joinAndLeave (item) {
      // console.log(item)
      let userId = localStorage.getItem('USER_ID')
      let username = JSON.parse(localStorage.getItem('USER_INFO')).userName
      console.log(username)
      let spaceId = item.id
      if (userId == item.projectOwnerId) {
        this.$message({
          type: 'warning',
          message: '无法离开该项目'
        })
        return
      }
      // console.log(userId)
      // console.log(spaceId)
      // 如果在项目里面就是离开操作
      if (item.inProject) {
        await this.$axios.get(`/api/project/deleteprojectuser?userId=${userId}&projectId=${spaceId}`).then(res => {
          // console.log(res)
          this.$message({
            type: 'success',
            message: res
          })
        })
        // 不在就是加入操作
      } else {
        // 加入操作
        await this.$axios.get(`/api/project/projectuser?userName=${username}&projectId=${spaceId}`).then(res => {
          if (res.userId) {
            this.$message({
              type: 'success',
              message: '已成功加入该项目'
            })
          }
        })
      }
      let index = this.projectList.findIndex(item2 => {
        item.id == item2.id
      })
      if (item.inProject == 0) {
        item.inProject = 1
      } else {
        item.inProject = 0
      }
      this.$set(this.projectList, index, item)
    }
  }
}
</script>

<style scoped lang="less">
	.userinfo-container {
		width: 100%;
		height: calc(100vh - 60px);

		.el-button--primary {
			background-color: #475065;
			border: none;
		}

		.userinfo-box {
			width: 100%;
			height: 100%;
			padding: 15px 25px;
			box-sizing: border-box;
			display: flex;
			justify-content: center;

			// background-color: #000000;
			.btn-container {
				// display: flex;
				// justify-content: space-between;
				align-items: center;
				padding: 0 20px 0 0;
				font-size: 18px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #343434;
				margin-bottom: 10px;
				// background-color: #fff;
				// background-color: #000000;
			}

			.show-container {
				display: flex;
				justify-content: center;
				text-align: left;
				// width: 100%;
				height: calc(100% - 20px);
				background-color: #fff;
				box-sizing: border-box;
				padding: 40px;
				overflow-y: auto;
				p {
					font-size: 30px;
					padding: 30px 0;
				}

				ul {
					.list-head {
						border-bottom: 1px solid #a8a8a8;
					}

					li {
						width: 900px;
						height: 50px;
						line-height: 50px;

						// background-color: #000000;
						span {
							display: inline-block;
							width: 20%;
							text-align: center;

							.el-button--text {
								color: #f75130;
							}
						}

						span:nth-child(1) {
							width: 40%;
						}
					}
				}
			}
		}
	}
</style>
